import React from "react";
import styled from "styled-components/macro";

const Container = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
`;
type Props = {
  text: string;
};
export default function NothingFound({ text }: Props) {
  return (
    <Container>
      <span>{text}</span>
    </Container>
  );
}
